import React, { useState } from "react";
import { Form, Input, Button, Spin, Row, Col } from "antd";
import { saveAs } from "file-saver"; // Import the FileSaver library to trigger file download
import ebookCover from "../images/ebook_cover_page.jpg";
import { useLoadScript } from "@react-google-maps/api";
import AddressAutocomplete from "./AddressAutoComplete";

interface EbookFormValues {
  name: string;
  email: string;
  phone: string;
  address: string;
}

const libraries: ("places")[] = ["places"];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const EbookPage: React.FC = () => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const [downloadReady, setDownloadReady] = useState(false); // Track download readiness

  // Phone number formatting
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 3) {
      value = `(${value}`;
    } else if (value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    form.setFieldsValue({ phone: value });
  };

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.formatted_address) {
      setAddress(place.formatted_address);
      form.setFieldsValue({ address: place.formatted_address }); // Update form state
    }
  };

  const onSubmit = async (values: EbookFormValues) => {
    setLoading(true); // Start loading

    const formData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      address: values.address || address,
    };

    try {
     const response = await fetch('/.netlify/functions/create-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Contact created successfully:', result);
        // If the form submission is successful, set downloadReady to true
        setDownloadReady(true);

        // Optionally, you could fetch a URL for the ebook or trigger the download directly
        const ebookUrl = "https://drive.google.com/uc?export=download&id=1rkegeO_Y7jYh8w_55DbOENGAtmrNCGy5";
        saveAs(ebookUrl, "How_to_Stop_Foreclosures.pdf"); // Trigger the download
      } else {
        console.error("Error submitting form:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="p-8">
      <Row gutter={16} align="middle">
        <Col xs={24} md={12}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            className="bg-white p-4 mb-8 rounded-lg shadow-lg"
          >
            <h1 className="text-2xl font-bold mb-4 text-center">Get Your Free Ebook</h1>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please input your phone number!" },
                { pattern: /^\(\d{3}\) \d{3}-\d{4}$/, message: "Please enter a valid phone number!" },
              ]}
            >
              <Input
                maxLength={14} // Limit the max length to match the formatted length
                placeholder="Phone"
                value={form.getFieldValue("phone")}
                onChange={handlePhoneChange}
              />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[{ required: true, message: "Please input your address!" }]}
            >
              <AddressAutocomplete
                onSelect={handlePlaceSelect}
                address={address}
                setAddress={setAddress}
                className="ant-input"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                className="font-bold"
                htmlType="submit"
                block
                disabled={downloadReady}
              >
                <Spin spinning={loading} tip="Submitting...">
                  Download Ebook
                </Spin>
              </Button>
            </Form.Item>

            {downloadReady && (
              <p className="font-bold text-green-500 text-center">
                Your ebook is ready!
              </p>
            )}
          </Form>
        </Col>

        <Col xs={24} md={12}>
          {/* Ebook cover on the right */}
          <div className="flex justify-center items-center h-full">
            <img
              src={ebookCover}
              alt="How to Stop Foreclosures"
              className="w-3/4 md:w-full max-w-xs"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EbookPage;
