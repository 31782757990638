import { StarOutlined } from "@ant-design/icons";
import "./Reasons.css";
import BackgroundImage from "../images/bg_house_1.jpg"

const Reasons = () => {
  const reasons = [
    "Inherited Properties", "Job Loss", "Damage", "Divorce", "Foreclosure",
    "Expensive Repairs", "Downsizing", "Bankruptcy", "Bad Tenants",
    "Relocating", "Retirement", "Health Issues"
  ];

  return (
    <div
      className="w-screen p-8 text-center bg-cover bg-fixed bg-center relative"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-70 blur-lg" />

      <div className="relative z-10 text-white max-w-3xl mx-auto p-6 bg-black bg-opacity-70 rounded-lg">
        <h3 className="text-2xl font-semibold mb-8">
          Some reasons our clients selling their house as-is for a cash offer:
        </h3>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 text-lg">
          {reasons.map((reason, index) => (
            <li key={index} className="flex items-center justify-center gap-2">
              <StarOutlined className="text-green-400" style={{ color: "#148b94" }}/>
              <span>{reason}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

};

export default Reasons;
