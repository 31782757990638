import { useState } from "react";
import { Form, Button } from "antd";
import { useLoadScript } from "@react-google-maps/api";
import AddressAutocomplete from "./AddressAutoComplete";
import { useNavigate } from "react-router-dom";
import "./AddressForm.css";

interface AddressFormValues {
  address: string;
}

const libraries: ("places")[] = ["places"];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

function AddressForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [address, setAddress] = useState("");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.formatted_address) {
      setAddress(place.formatted_address);
      form.setFieldsValue({ address: place.formatted_address });
    }
  };

  const onSubmit = async (values: AddressFormValues) => {
    const addressData = values.address || address;

    // Use URLSearchParams to construct the query string
    const params = new URLSearchParams({ address: addressData });

    navigate(`/get-your-offer/?${params.toString()}`);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div
      className="mt-4 w-2/3 bg-transparent shadow-lg rounded-lg"
      style={{ border: '4px solid #148d96' }}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        className="shadow-lg"
      >
        <Form.Item
          name="address"
          rules={[{ required: true, message: "Please input your address!" }]}
          className="mb-0"
        >
          <AddressAutocomplete
            onSelect={handlePlaceSelect}
            address={address}
            setAddress={setAddress}
            className="h-16 text-lg w-full px-4 rounded-b-none rounded-t"
          />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button
            className="w-full h-16 text-lg rounded-t-none rounded-b font-bold custom-button"
            type="primary"
            htmlType="submit"
          >
            Get My Offer!
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddressForm;
