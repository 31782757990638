import React from 'react';

interface HoverIconCircleProps {
  children: React.ReactNode;
  size?: string;       // Optional size prop (e.g., 'h-16 w-16' for height and width)
  bgColor?: string;    // Optional background color (e.g., 'bg-cyan-500')
  hoverBgColor?: string; // Optional hover background color (e.g., 'hover:bg-cyan-600')
}

const HoverIconCircle: React.FC<HoverIconCircleProps> = ({
  children,
  size = 'h-16 w-16',
  bgColor = 'bg-cyan-500',
  hoverBgColor = 'hover:bg-cyan-600',
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full ${size} ${bgColor} ${hoverBgColor} transition-all duration-300 ease-in-out`}
    >
      <div className="transition-transform duration-300 ease-in-out transform">
        {children}
      </div>
    </div>
  );
};

export default HoverIconCircle;
