import React from "react";
import ContactForm from "./ContactForm"; // Make sure to adjust the path to your ContactForm component

const ContactPage: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row p-4">
      <div className="md:w-1/2">
        <ContactForm />
      </div>
      <div className="md:w-1/2 flex flex-col justify-center items-center">
        <h2 className="text-xl font-semibold mb-4">Why Choose Us?</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> Zero Fees to Sell Your House
          </li>
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> Highest Off-Market Price
          </li>
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> Sell As-Is. No Cleanup. No Repairs.
          </li>
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> No Closing Costs
          </li>
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> No Realtors
          </li>
          <li className="flex items-center">
            <span className="text-green-500 mr-2">✔️</span> No Showings
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactPage;
