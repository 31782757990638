// src/components/BenefitsComparison.tsx

import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const BenefitsComparison = () => {
  return (
    <div className="w-screen p-8 bg-gray-100">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Why Sell Your House for Cash in Texas?
      </h2>
      <p className="mb-8 text-start">
        Honest Option offers the easiest and fastest way to sell your Texas home. No listing fees, agent commissions, or delays – just a quick, hassle-free closing process. Skip the stress of repairs, agents, and the lengthy sales process. We’re here to make selling your house a breeze. Check out the benefits of selling with us versus the traditional method:
      </p>

      <div className="flex flex-col md:flex-row gap-6 justify-center items-start max-w-5xl mx-auto">
        {/* Cash Offer Program Section */}
        <div className="flex-1 bg-teal-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 transform hover:scale-105">
          <h3 className="text-xl font-semibold mb-2 text-teal-800">Sell with Our Cash Offer Program</h3>
          <p className="mb-2 italic underline font-semibold text-teal-700">Get a fair, no-hassle cash offer from Honest Option</p>
          <p className="mb-4 text-teal-600 text-start">Sell your house quickly and easily – no repairs, no agents, just cash in your pocket.</p>

          <ul className="space-y-4 text-teal-700">
            <li className="flex items-start">
              <span className="text-left">
                <CheckCircleOutlined className="text-teal-600 mr-2" />
                <strong>Get a competitive cash offer within 24 hours</strong> – We’ll provide a no-obligation cash offer, and you can choose your closing day. Our local market expertise ensures a fair deal.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-left">
                <CheckCircleOutlined className="text-teal-600 mr-2" />
                <strong>No fees, no commissions, no closing costs</strong> – What we offer is what you get. No hidden costs or deductions.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-left">
                <CheckCircleOutlined className="text-teal-600 mr-2" />
                <strong>We buy your house AS-IS</strong> – No need for repairs or updates. We handle everything.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-left">
                <CheckCircleOutlined className="text-teal-600 mr-2" />
                <strong>Repairs are on us</strong> – If your home needs repairs, we’ll take care of it. You can even leave unwanted items behind, and we’ll haul them away at no extra charge.
              </span>
            </li>
          </ul>
        </div>

        {/* Traditional Way Section */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-md transition-transform duration-300 transform hover:scale-105">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">Sell the Traditional Way</h3>
          <p className="mb-2 italic underline font-semibold text-gray-700">Listing with an agent</p>
          <p className="mb-4 text-gray-600 text-start">Selling through a realtor can be time-consuming, expensive, and stressful. Consider the trade-offs.</p>

          <ul className="space-y-4 text-gray-700">
            <li className="flex items-start">
              <span className="text-left">
                <CloseCircleOutlined className="text-red-500 mr-2" />
                <strong>It may take months to sell</strong> – Your house could sit on the market for weeks or months, costing you money while waiting for an offer.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-left">
                <CloseCircleOutlined className="text-red-500 mr-2" />
                <strong>Fees and commissions add up</strong> – Seller fees, commissions (usually 6%), and closing costs reduce your profits significantly.
              </span>
            </li>
            <li className="flex items-center">
              <span className="text-left">
                <CloseCircleOutlined className="text-red-500 mr-2" />
                <strong>Repairs and updates are your responsibility</strong> – You’ll have to invest time and money into repairs and updates to get your house ready for listing.
              </span>
            </li>
            <li className="flex items-center">
              <span className="text-left">
                <CloseCircleOutlined className="text-red-500 mr-2" />
                <strong>Potential surprise repair requests</strong> – After an inspection, you may be asked to make costly repairs before moving forward with the sale.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BenefitsComparison;
