import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Row, Col, InputNumber, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface QuestionFormValues {
  address: string;
  reasonForSelling: string;
  timeline: string;
  askingPrice: string;
  priceJustification: string;
  recentUpdates: string;
  repairsNeeded: string;
  wishList: string;
  additionalInfo: string;
  additionalHelp: string;
  mortgageDetails: string;
  interestRate: string;
  monthlyPayment: string;
  secondLiens: string;
  bankInteraction: string;
  bestTimeToReach: string;
}

const QuestionForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [contactDetails, setContactDetails] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    timeline: "",
  });
  const [loading, setLoading] = useState(false);  // Track loading state

  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    setContactDetails({
      name: params.get("name") || "",
      phone: params.get("phone") || "",
      email: params.get("email") || "",
      address: params.get("address") || "",
      timeline: params.get("timeline") || "",
    });
  };

  useEffect(() => {
    getQueryParams(location.search);
  }, [location.search]);

  useEffect(() => {
    form.setFieldsValue({
      address: contactDetails.address,
      timeline: contactDetails.timeline,
    });
  }, [contactDetails, form]);

  const onSubmit = async (values: QuestionFormValues) => {
    setLoading(true);  // Start loading

    const formData = {
      answers: {
        reasonForSelling: values.reasonForSelling,
        timeline: values.timeline,
        askingPrice: values.askingPrice,
        priceJustification: values.priceJustification,
        recentUpdates: values.recentUpdates,
        repairsNeeded: values.repairsNeeded,
        wishList: values.wishList,
        additionalInfo: values.additionalInfo,
        additionalHelp: values.additionalHelp,
        mortgageDetails: values.mortgageDetails,
        interestRate: values.interestRate,
        monthlyPayment: values.monthlyPayment,
        secondLiens: values.secondLiens,
        bankInteraction: values.bankInteraction,
        bestTimeToReach: values.bestTimeToReach,
      },
      name: contactDetails.name,
      email: contactDetails.email,
      phone: contactDetails.phone,
      address: contactDetails.address,
    };

    try {
      const response = await fetch("/.netlify/functions/submit-answers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Form submitted successfully:", result);
        const queryParams = new URLSearchParams({ name: contactDetails.name });
        navigate(`/thank-you?${queryParams.toString()}`);
      } else {
        console.error("Error submitting form:", result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  return (
    <div className="p-4 flex justify-center bg-[#e6f7f8] min-h-screen">
      <div className="w-full lg:w-3/4 p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">
          Thanks {contactDetails.name}! Just a Few Quick Questions
        </h2>
        <h2 className="text-l mb-4">
          We'd love to learn more about your property so we can make you the best offer possible. Your answers will help us get everything right, ensuring you get the best deal.
        </h2>
        <h2 className="text-l mb-4">
          If you prefer, feel free to give us a call at (469) 942-5299, and we can walk through these questions together. Whatever works best for you!
        </h2>
        <Spin spinning={loading} size="large" tip="Submitting...">
          <Form layout="vertical" onFinish={onSubmit} form={form} className="p-4 rounded-lg">
            <Form.Item label="What is the address?" name="address">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Why do you want to sell?" name="reasonForSelling">
              <Input.TextArea />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item label="How soon do you need to close?" name="timeline">
                  <Select>
                    <Select.Option value="30 days or less">30 days or less</Select.Option>
                    <Select.Option value="1-3 months">1-3 months</Select.Option>
                    <Select.Option value="3-6 months">3-6 months</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="How much do you want for it?" name="askingPrice">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonBefore="$"
                    formatter={(value) =>
                    value ? (value as unknown as string).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '' // Add comma separators
                    }
                    parser={(value: string | undefined): number =>
                    value ? parseFloat(value.replace(/(,*)/g, '')) : 0 // Remove commas and convert to number
                    }
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="How did you come up with that price?" name="priceJustification">
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Have you done any updates on the house before?" name="recentUpdates">
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="What repairs are needed?" name="repairsNeeded">
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Anything else I should know?" name="additionalInfo">
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Anything else you need help with?" name="additionalHelp">
              <Input.TextArea />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item label="What is the mortgage on the property?" name="mortgageDetails">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonBefore="$"
                    formatter={(value) =>
                    value ? (value as unknown as string).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '' // Add comma separators
                    }
                    parser={(value: string | undefined): number =>
                    value ? parseFloat(value.replace(/(,*)/g, '')) : 0 // Remove commas and convert to number
                    }
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="What is the interest rate?" name="interestRate">
                  <InputNumber style={{ width: '100%' }} addonAfter="%" min={0} max={100} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="What is the monthly payment?" name="monthlyPayment">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonBefore="$"
                    formatter={(value) =>
                    value ? (value as unknown as string).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                    }
                    parser={(value: string | undefined): number =>
                    value ? parseFloat(value.replace(/(,*)/g, '')) : 0
                    }
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="Any 2nd liens?" name="secondLiens">
                  <Select>
                    <Select.Option value="yes">Yes</Select.Option>
                    <Select.Option value="no">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="If PFC: what has the bank done with you so far?" name="bankInteraction">
              <Input.TextArea />
            </Form.Item>

            <Form.Item label="Best time to reach you?" name="bestTimeToReach">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default QuestionForm;
