import AddressForm from "./AddressForm";
import "./Home.css";
import ProcessCards from "./ProcessCards";
import Reasons from "./Reasons";
import Situations from "./Situations";
import BenefitsComparison from "./BenefitsSection";
import AboutSection from "./AboutSection";
import { Button } from "antd";
import { Link } from "react-router-dom";


export default function Home() {
  return (
    <div className="container text-center">
      {/* hero section */}
      <div className="offer-section w-screen h-[75vh] text-white py-20 shadow-lg flex justify-around">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold">Get A Guaranteed Free & Fast Cash Offer Today</h1>
          <p className="mt-4 text-xl">Get A Fair Cash Offer From A Trusted Cash Home Buyer.</p>
          <p className="mt-4 text-xl">No Realtors, No Fees, No Repairs.</p>
          <AddressForm />
        </div>
      </div>

      {/* about section */}
      {/* <div className="w-screen bg-white py-16 px-8 text-center">
      </div> */}


      {/* process section */}
      <div className="w-screen bg-white py-16 px-8 text-center">
        <h1 className="text-4xl font-bold">Our Process Is Simple</h1>
        <p className="mt-4 text-lg">Sell Your House Fast For Cash In 3 Simple Steps.</p>
        <ProcessCards />
      </div>

      {/* benefits section */}
      <Reasons />

      {/* about section */}
      <AboutSection />

      {/* more info section*/}
      <Situations />

      {/* Benefits of Selling My House for Cash in Texas */}
      <BenefitsComparison />

      {/* ebook section */}
      <div className="w-screen p-8">
        <h2 className="text-2xl font-bold mb-4 text-center">Get Our Free Ebook!</h2>
        <p className="text-center">
          We are so excited to launch our ebook, <strong>"How to Stop Foreclosures"</strong>.
        </p>
        <p>
          Download a free copy here to learn valuable tips and insights on how to protect your home from foreclosure.
        </p>
        <div className="flex justify-center mt-4">
          <Link to="/ebook">
            <Button type="primary" size="large" className="font-bold">
              Download Ebook!
            </Button>
          </Link>
        </div>
      </div>


    </div>
  );
};
