// src/components/AboutSection.tsx

import { Button } from "antd";
import companyLogo from "../images/logo_honest_option.jpg";
import profilePhoto from "../images/profile_steve.jpg";

const AboutSection = () => {
  return (
    <div className="w-screen flex flex-col md:flex-row items-center p-8 bg-gray-100 space-y-6 md:space-y-0 md:space-x-6">
      {/* Left: Profile Photo */}
      <div className="flex-shrink-0 w-40 h-40 md:w-56 md:h-56 rounded-full overflow-hidden shadow-lg">
        <img
          src={profilePhoto}
          alt="Profile"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right: Text and Button */}
      <div className="flex-1 flex flex-col items-center md:items-center text-center md:text-left">
        {/* Company Logo */}
        <div className="mb-4">
          <img src={companyLogo} alt="Company Logo" className="w-32 h-auto" />
        </div>

        {/* Description Text */}
        <div className="mx-8 text-gray-700 leading-relaxed mb-4">
          <p className="mb-4">
            Thank you for considering us to sell your home! We understand that selling a property can feel overwhelming, but we're here to make the process as simple and stress-free as possible.
          </p>
          <p className="mb-4">
            Our mission is to offer homeowners a fast, hassle-free solution for selling their homes. With our expertise and experience, we handle the complexities of every property, ensuring everything is taken care of smoothly and efficiently.
          </p>
          <p className="mb-4">
            Simply fill out the form below for a quick online quote, or give us a call at <a href="tel:+14699425299" className="text-teal-600 font-semibold">(469) 942-5299</a>. We're here to help!
          </p>
        </div>

        {/* Contact Button */}
        <Button
          type="primary"
          size="large"
          href="/contact"
          className="font-bold bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 flex items-center justify-center"
        >
          Get My Cash Offer!
        </Button>
      </div>
    </div>
  );
};

export default AboutSection;
