import { useEffect, useState } from "react";
import { Form, Input, Button, Select, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  timeline: string;
}

const ContactFormNoAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState(false); // State for loading spinner

  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    const addressParam = params.get("address");
    if (addressParam) {
      setAddress(addressParam);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 3) {
      value = `(${value}`;
    } else if (value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    form.setFieldsValue({ phone: value });
  };

  useEffect(() => {
    getQueryParams(location.search); // Parse query params on component mount
  }, [location.search]);

  const onSubmit = async (values: FormValues) => {
    setLoading(true); // Show the spinner when submitting
    const contactData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      address: address,
      timeline: values.timeline
    };

    try {
      const response = await fetch('/.netlify/functions/create-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Contact created successfully:', result);
        const queryParams = new URLSearchParams({
          name: contactData.name,
          phone: contactData.phone,
          email: contactData.email,
          address: contactData.address,
          timeline: contactData.timeline,
        });

        navigate(`/questions?${queryParams.toString()}`);
      } else {
        console.error('Error creating contact:', result);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false); // Hide the spinner after submission
    }
  };

  return (
    <div className="p-8">
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        className="bg-white p-4 rounded-lg shadow-lg"
      >
        <h2 className="text-2xl font-bold mb-4 text-center">Complete Your Information</h2>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "Please input your phone number!" },
            { pattern: /^\(\d{3}\) \d{3}-\d{4}$/, message: "Please enter a valid phone number!" },
          ]}
        >
          <Input
            maxLength={14} // Limit the max length to match the formatted length
            placeholder="Phone"
            value={form.getFieldValue('phone')}
            onChange={handlePhoneChange}
          />
        </Form.Item>

        <Form.Item
          name="timeline"
          rules={[{ required: true, message: "Please select a closing time!" }]}
        >
          <Select placeholder="How soon are you looking to sell?">
            <Select.Option value="30 days or less">30 days or less</Select.Option>
            <Select.Option value="1-3 months">1-3 months</Select.Option>
            <Select.Option value="3-6 months">3-6 months</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      {loading && <Spin size="large" className="absolute inset-0 flex justify-center items-center" />}
    </div>
  );
};

export default ContactFormNoAddress;
