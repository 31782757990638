import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../images/logo_honest_option.jpg';
import './Header.css';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';

const Header: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Hook for programmatic navigation

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Function to determine if the item is selected
  const isSelected = (path: string) => location.pathname === path;

  const handleMenuClick = (path: string) => {
    navigate(path); // Navigate to the specified path
    closeDrawer(); // Close the drawer
  };

  return (
    <header className="w-full bg-white shadow-md">
      <div className="bg-primary text-white text-end py-2 pr-4">
        Call us at (469) 942-5299
      </div>
      <div className="max-w-screen-xl mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-bold">
          <Link to="/">
            <img src={Logo} alt="Company Logo" className="h-12 w-auto" />
          </Link>
        </div>

        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-6 mr-4">
          <Link to="/" className={`menu-item ${isSelected('/') ? 'selected' : ''}`}>Home</Link>
          <Link to="/ebook" className={`menu-item ${isSelected('/ebook') ? 'selected' : ''}`}>Free Ebook</Link>
          <Link to="/contact" className={`menu-item ${isSelected('/contact') ? 'selected' : ''}`}>Contact</Link>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <MenuOutlined className="menu-icon text-2xl" onClick={toggleDrawer} />
        </div>

        {/* Mobile Drawer */}
        <Drawer
          title="Menu"
          placement="right"
          onClose={closeDrawer}
          open={isDrawerOpen}
        >
          <Menu mode="vertical">
            <Menu.Item
              key="home"
              className={isSelected('/') ? 'selected' : ''}
              onClick={() => handleMenuClick('/')} // Use onClick for redirection
            >
              Home
            </Menu.Item>
            <Menu.Item
              key="ebook"
              className={isSelected('/ebook') ? 'selected' : ''}
              onClick={() => handleMenuClick('/ebook')}
            >
              Free Ebook
            </Menu.Item>
            <Menu.Item
              key="contact"
              className={isSelected('/contact') ? 'selected' : ''}
              onClick={() => handleMenuClick('/contact')}
            >
              Contact
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </header>
  );
};

export default Header;
