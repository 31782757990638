import React, { useEffect, useRef } from "react";
import { Input, InputRef } from "antd"; // Import Input and InputRef from Ant Design
import classNames from "classnames";

interface AddressAutocompleteProps {
  onSelect: (place: google.maps.places.PlaceResult) => void;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
}

function AddressAutocomplete({ onSelect, address, setAddress, className }: AddressAutocompleteProps) {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<InputRef | null>(null); // Use InputRef for the ref

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  useEffect(() => {
    if (!autocompleteRef.current && inputRef.current) {
      // Safely access the input element
      const inputElement = inputRef.current.input; // Access the underlying HTML input element

      if (inputElement) {
        autocompleteRef.current = new window.google.maps.places.Autocomplete(inputElement, {
          componentRestrictions: { country: "us" },
          fields: ["formatted_address", "geometry", "address_components"],
        });

        autocompleteRef.current.addListener("place_changed", () => {
          const place = autocompleteRef.current?.getPlace();
          if (place && place.formatted_address) {
            onSelect(place);
            setAddress(place.formatted_address);
          }
        });
      }
    }
  }, [onSelect, setAddress]);

  return (
    <Input
      ref={inputRef} // Use the ref here
      value={address}
      onChange={handleInputChange}
      className={classNames(className)} // Pass any additional class names
      placeholder="Enter your home address"
    />
  );
}

export default AddressAutocomplete;
