

const ThankYouPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-green-300 to-blue-500 text-center p-8">
      <h1 className="text-6xl font-extrabold text-white drop-shadow-lg">
        Thank You!
      </h1>
      <p className="mt-6 p-4 text-2xl text-white">
        We're excited to review your property and will be in touch soon with your personalized cash offer and next steps.
      </p>
      <p className="mt-4 p-4 text-2xl text-white">
        In the meantime, if you need immediate assistance, feel free to give us a call at (469) 942-5299. We're here to help!
      </p>
      <div className="mt-8">
        <a href="/"
          className="inline-block px-6 py-3 text-lg font-semibold text-white bg-primary rounded-lg shadow-lg hover:bg-primary-dark transition duration-300"
        >
          Go Back to Home
        </a>
      </div>
    </div>
  );
};

export default ThankYouPage;
