import SituationCard from "./SituationCard";
import BackgroundImage from "../images/bg_highway_1.jpg";

const situations = [
  { title: "Too many repairs to deal with", description: "Received a renovation quote to get your house fixed and can’t afford the costs?" },
  { title: "Inherited a property", description: "Inherited property and need to sell it before probate is complete?" },
  { title: "Going through divorce", description: "Going through a divorce and want to sell a house within your timeframe and just be done?" },
  { title: "Stop foreclosure", description: "In default on your mortgage or taxes, and the house is scheduled for auction?" },
  { title: "Moving out of state", description: "Need to relocate for work or retirement and need a quick seamless sale?" },
  { title: "Tired of dealing with tenants", description: "Sick of being a landlord and renting to tenants who trash your home and don’t pay rent when it’s due?" },
];

const Situations = () => {
  return (
    <div
      className="w-screen p-8 text-center bg-cover bg-fixed bg-center relative"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-75 blur-lg"></div>

      <div className="relative max-w-4xl mx-auto py-16 text-white">
        <h2 className="text-2xl font-bold mb-6">
          We Buy Houses In Texas In ANY Situation
        </h2>
        <p className="mb-8 text-start">
          No matter the challenge, we’ve seen it all and we’re here to help. Facing foreclosure? Going through a divorce? Relocating? Struggling with an upside-down mortgage or liens? Whether you live in the home, rent it out, or it's vacant (even if it’s not habitable), we’ve got you covered.

          We've helped homeowners dealing with inherited property, unpaid tax liens, unpaid mortgage payments, or simply needing to downsize. Even if your house is in need of repairs you can’t afford, has fire damage, or has difficult tenants, Honest Option House Buyers can still make an offer. Whether you’ve listed the property before or not, we will work with you through any situation.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {situations.map((situation, index) => (
            <SituationCard
              key={index}
              title={situation.title}
              description={situation.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Situations;
