// src/components/ProcessCards.tsx

import React from 'react';
import { SignatureOutlined, MessageOutlined, DollarOutlined } from "@ant-design/icons";
import HoverIconCircle from "./HoverIconCircle";

interface CardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const InfoCard = ({ title, description, icon }: CardProps) => (
  <div className="w-full md:max-w-xs p-4 bg-white rounded-lg shadow-md group hover:shadow-lg transition-shadow duration-300 text-center flex flex-col justify-between" style={{ minHeight: '250px' }}>
    <div className="flex justify-center mb-4">{icon}</div>
    <h2 className="text-xl font-semibold mb-2 group-hover:text-[#47acaf] transition-colors duration-300"> {/* Updated hover color */}
      {title}
    </h2>
    <p className="text-gray-700">{description}</p>
  </div>
);

const ProcessCards = () => (
  <div className="flex flex-col md:flex-row justify-around items-center md:space-x-4 mt-10 space-y-4 md:space-y-0">
    <InfoCard
      title="Submit Your Info"
      description="Simply provide your details, and one of our friendly team members at Honest Option House Buyers will reach out to schedule a no-obligation consultation."
      icon={
        <HoverIconCircle
          size="h-16 w-16"
          bgColor="bg-[#148d96]"  // Custom color for background
          // hoverBgColor="hover:bg-blue-600"
        >
          <SignatureOutlined className="text-white text-4xl group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300 ease-in-out" />
        </HoverIconCircle>
      }
    />
    <InfoCard
      title="Let's Talk"
      description="Connect with one of our real estate professionals to discuss the specifics of your property and explore your options."
      icon={
        <HoverIconCircle
          size="h-16 w-16"
          bgColor="bg-[#148d96]"  // Custom color for background
          // hoverBgColor="hover:bg-blue-600"
        >
          <MessageOutlined className="text-white text-4xl group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300 ease-in-out" />
        </HoverIconCircle>
      }
    />
    <InfoCard
      title="Receive Your Cash Offer"
      description="Once you're happy with our offer, we'll finalize the deal on your terms, and you'll have cash in hand quickly. It’s that simple!"
      icon={
        <HoverIconCircle
          size="h-16 w-16"
          bgColor="bg-[#148d96]"  // Custom color for background
          // hoverBgColor="hover:bg-blue-600"
        >
          <DollarOutlined className="text-white text-4xl group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300 ease-in-out" />
        </HoverIconCircle>
      }
    />
  </div>
);

export default ProcessCards;
