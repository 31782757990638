// src/components/SituationCard.tsx

import React from "react";

interface SituationCardProps {
  title: string;
  description: string;
}

const SituationCard: React.FC<SituationCardProps> = ({ title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:bg-gray-100 transition-transform duration-300 ease-in-out">
      <h3 className="font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default SituationCard;
